@import '../variables.css';

/* =================== MENU SELECTION STYLING =================== */
.menu-container {
    text-align: center;
    padding: 100px 0 60px 0;
}

.menu-title {
    font-size: var(--font-size-xl);
    font-weight: 700;
    color: var(--primary-color);
    margin-top: 20px;
    text-align: left;
    margin-left: 50px;
}

.menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 78px;
    margin-bottom: 20px;
}

.generate-list-button {
    width: 226px;
    height: 48px;
    background: var(--primary-color);
    border-radius: var(--border-radius-sm);
    border: none;
    color: var(--white);
    font-size: var(--font-size-base);
    font-weight: bold;
    cursor: pointer;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.generate-list-button:hover {
    background: var(--accent-color);
}

.menu-description {
    font-size: var(--font-size-base);
    color: var(--text-color);
    max-width: 623px;
    margin: 10px 50px;
    text-align: left;
}

.meal-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.meal-card {
    width: 173px;
    height: 198px;
    background: var(--white);
    border-radius: var(--border-radius-sm);
    text-align: center;
    position: relative;
}

.meal-image {
    width: 173px;
    height: 115px;
}

.meal-image img {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-sm) 6px 0 0;
}

.lock-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 44px;
    height: 44px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.lock-button img {
    width: 100%;
    height: 100%;
}

.meal-day {
    font-size: var(--font-size-base);
    font-weight: 700;
    color: var(--text-color);
    margin-top: 10px;
}

.meal-name {
    font-size: 0.875rem;
    color: var(--text-color);
    text-align: left;
    padding: 0 6px;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
    max-height: calc(1.4em * 2);
}

.meal-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    justify-content: center;
}

.meal-list>* {
    margin-bottom: 40px;
}

/* Actions (Shuffle & Continue) */
.menu-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.shuffle-button,
.continue-button {
    width: 164px;
    height: 48px;
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-base);
    font-weight: bold;
    cursor: pointer;
}

.shuffle-button {
    background: var(--secondary-color);
    border: var(--border-thickness) solid var(--primary-color);
}

.continue-button {
    background: var(--secondary-color);
}

.continue-button:enabled {
    background: var(--primary-color);
}

.grey-box {
    width: 173px;
    height: 115px;
    background: var(--light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    color: var(--dark-gray);
    border-radius: var(--border-radius-sm);
}

.bon-appetit {
    display: none;
}

@media (max-width: 768px) {
    .meal-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: center;
    }

    .meal-card {
        width: 100%;
    }

    .meal-card:nth-child(7) {
        grid-column: 1;
    }

    .bon-appetit {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        font-size: 3.5rem;
        font-weight: bold;
        color: var(--primary-color);
        height: 198px;
    }
}

/* Mobile View: Stack cards vertically */
@media screen and (max-width: 768px) {
    .meal-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        height: 100vh;
        padding: 20px 0;
    }

    .flip-container {
        width: 90vw;
        height: 85vh;
        margin-bottom: -120px;
    }

    .recipe-card {
        width: 100%;
        height: 100%;
    }
}