/* =================== COLORS =================== */
:root {
    --primary-color: #067121;
    --secondary-color: #9BC6A6;
    --accent-color: #055a1a;
    --background-color: #A0C8AA;
    --text-color: #001B2E;
    --light-gray: #d3d3d3;
    --dark-gray: #555;
    --border-color: black;
    --white: #FFFFFF;

    /* Button Colors */
    --button-primary-bg: var(--primary-color);
    --button-secondary-bg: var(--secondary-color);
    --button-hover-bg: var(--accent-color);
    --button-text-color: var(--white);

    /* Card Backgrounds */
    --card-bg: var(--white);
    --modal-bg: var(--white);

    /* Misc */
    --shadow-light: 0px 4px 10px rgba(0, 0, 0, 0.1);
    --tooltip-bg: rgba(0, 0, 0, 0.8);
}

/* =================== TYPOGRAPHY =================== */
:root {
    --font-primary: "Poppins", sans-serif;
    --font-secondary: "Inter", sans-serif;
    --font-handwritten: "Indie Flower", cursive;

    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-base: 1rem;
    --font-size-md: 1.125rem;
    --font-size-lg: 1.5rem;
    --font-size-xl: 2.25rem;
    --font-size-xxl: 6rem;
}

/* =================== SPACING =================== */
:root {
    --spacing-xs: 0.375rem;
    --spacing-sm: 0.625rem;
    --spacing-md: 0.75rem;
    --spacing-lg: 1.25rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 3.125rem;
}

/* =================== COMPONENTS =================== */
:root {
    --border-radius-sm: 0.375rem;
    --border-radius-md: 0.75rem;
    --border-radius-lg: 1.25rem;
    --border-thickness: 0.125rem;
}

/* =================== BUTTONS =================== */
button {
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-base);
    font-weight: bold;
    cursor: pointer;
    padding: var(--spacing-md) var(--spacing-xl);
}

.button-primary {
    background: var(--button-primary-bg);
    color: var(--button-text-color);
    border: none;
}

.button-secondary {
    background: var(--button-secondary-bg);
    color: var(--text-color);
}

.button-primary:hover,
.button-secondary:hover {
    background: var(--button-hover-bg);
}

/* =================== CARDS =================== */
.card {
    background: var(--card-bg);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-light);
}

/* =================== MODAL =================== */
.modal {
    background: var(--modal-bg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-light);
}

/* =================== TOOLTIP =================== */
.tooltip {
    background: var(--tooltip-bg);
    color: var(--white);
    font-size: var(--font-size-sm);
    padding: var(--spacing-xs);
    border-radius: var(--border-radius-sm);
}