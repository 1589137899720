@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
@import '../variables.css';

.grocery-list-container {
    font-family: var(--font-handwritten);
    letter-spacing: 0.1rem;
    background-image: linear-gradient(90deg, #e8b6b6, 2px, rgba(255, 255, 255, 0), 2px, rgba(255, 255, 255, 0)),
        linear-gradient(0, #d9eaf3, 1px, rgba(255, 255, 255, 0), 2px, rgba(255, 255, 255, 0));
    background-position: 4rem 0;
    background-size: 100% 2.4rem;
    color: #111;
    background-color: var(--white);
    margin-top: 88px;
    padding-top: 0;
    white-space: nowrap;
    counter-reset: listCounter;
}

.grocery-list-container h1,
.grocery-list-container h2,
.grocery-list-container ul {
    padding-left: 65px;
}

.grocery-list-container h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.grocery-list-container h2 {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.6rem;
    text-indent: 45px;
    position: relative;
}

.grocery-list-container h2::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #111;
    position: absolute;
    bottom: -5px;
    left: 65px;
}

.grocery-list-container ul {
    list-style: none;
    padding: 0;
    font-size: 1.5rem;
}

.grocery-list-container li {
    margin-bottom: 0.6rem;
    padding-left: 65px;
}

.grocery-list-container li::before {
    content: counter(listCounter) ". ";
    counter-increment: listCounter;
}