@import '../variables.css';

/* =================== RECIPE CARD STYLING =================== */
.flip-container {
    perspective: 1000px;
    cursor: pointer;
    width: 312px;
    height: 443px;
}

.recipe-card {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-sm);
    background: var(--white);
    box-shadow: var(--shadow-light);
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s ease-in-out;
}

/* Hint animation on load for the first card only */
.recipe-card.hint-animation {
    transition: transform 0.8s ease-in-out;
}

.recipe-card.flipped {
    transform: rotateY(180deg);
}

.recipe-card-front,
.recipe-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    border-radius: var(--border-radius-sm);
}

/* =================== FRONT SIDE =================== */
.recipe-card-front {
    background: var(--white);
    justify-content: flex-start;
}

.recipe-image {
    border-radius: var(--border-radius-sm) 6px 0 0;
}

.image-container {
    position: relative;
}

.meal-category {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 132px;
    border-radius: var(--border-radius-lg);
    background: rgba(255, 255, 255, 0.7);
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: var(--font-size-base);
    color: #0F0E0E;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.recipe-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.recipe-title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--font-size-base);
    color: var(--text-color);
    text-align: left;
    margin-top: 20px;
    width: 100%;
}

.ingredients-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.ingredients-tooltip .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: var(--tooltip-bg);
    color: #fff;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 0.85rem;
}

.ingredients-tooltip:hover .tooltip-text {
    visibility: visible;
}

.recipe-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    margin-top: auto;
}

.recipe-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.view-recipe-button,
.view-instructions-button {
    flex: 1;
    height: 48px;
    padding: 12px 0;
    border-radius: var(--border-radius-sm);
    border: var(--border-thickness) solid var(--primary-color);
    background: none;
    font-size: var(--font-size-base);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    white-space: nowrap;
}

.view-recipe-button {
    color: var(--primary-color);
    background: var(--white);
    height: 48px;
}

.view-instructions-button {
    color: var(--white);
    background: var(--primary-color);
    height: 48px;
}

.view-instructions-button:hover {
    background: var(--accent-color);
}

/* =================== BACK SIDE =================== */
.recipe-card-back {
    background: var(--white);
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.recipe-card-back h3 {
    font-size: var(--font-size-md);
    font-weight: bold;
}

.recipe-card-back p {
    font-size: var(--font-size-sm);
    margin: 5px 0;
}

.flip-text {
    font-size: var(--font-size-xs);
    margin-top: 10px;
    opacity: 0.6;
}

.flip-instruction {
    font-size: 0.8rem;
    color: #777;
    text-align: center;
}

/* =================== NUTRITION FACTS =================== */
.performance-facts {
    border: 1px solid var(--border-color);
    padding: 10px;
    width: 90%;
    text-align: left;
}

.performance-facts__title {
    font-weight: bold;
    font-size: 1.5rem;
}

.performance-facts__table {
    width: 100%;
    border-collapse: collapse;
}

.performance-facts__table th,
.performance-facts__table td {
    padding: 5px;
    border-top: 1px solid var(--border-color);
}

.thick-row th,
.thick-row td {
    border-top: 5px solid var(--border-color);
}

/* =================== MODAL STYLING =================== */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: var(--white);
    padding: 20px;
    border-radius: var(--border-radius-sm);
    width: 400px;
    text-align: center;
}

.close-modal {
    background: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    margin-top: 10px;
}

.close-modal:hover {
    background: var(--accent-color);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-container {
    width: 680px;
    height: 737px;
    background: var(--white);
    border-radius: var(--border-radius-md);
    padding: 20px;
    position: relative;
    box-shadow: var(--shadow-light);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 44px;
    height: 44px;
    border-radius: var(--border-radius-lg);
    background: rgba(255, 255, 255, 0.7);
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-close:hover {
    background: rgba(255, 255, 255, 1);
}

/* =================== RECIPE MODAL CONTENT =================== */
.modal-image {
    width: 680px;
    height: 224px;
    border-radius: var(--border-radius-sm) 6px 0 0;
    object-fit: cover;
}

.modal-title {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: var(--font-size-xl);
    text-align: center;
    color: var(--text-color);
    margin-top: 20px;
}

.modal-info {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: var(--font-size-base);
    margin-top: 10px;
}

.modal-instructions {
    width: 526px;
    height: 340px;
    overflow-y: auto;
    padding: 10px;
    text-align: left;
    font-size: var(--font-size-sm);
    background: #f9f9f9;
    border-radius: var(--border-radius-sm);
    margin-top: 10px;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.modal-instructions::-webkit-scrollbar {
    width: 6px;
}

.modal-instructions::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.modal-instructions::-webkit-scrollbar-track {
    background: transparent;
}