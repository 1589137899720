@import '../variables.css';

body {
  background: var(--background-color);
  margin: 0;
  font-family: var(--font-primary);
}

/* =========================  NAVBAR  ========================= */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-color);
  padding: 0 20px;
  z-index: 1000;
  background-color: var(--background-color);
}

.logo-container {
  position: absolute;
  left: 32px;
  top: 30px;
}

.logo {
  width: 278px;
  height: auto;
  max-width: 300px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.logo:hover {
  opacity: 0.8;
}

/* =========================  CONTENT (DESKTOP) ========================= */
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 150px auto 0 auto;
  flex-wrap: nowrap;
}

.cta-section {
  max-width: 811px;
  margin-left: 99px;
}

.cta-title {
  font-size: var(--font-size-xxl);
  font-weight: 700;
  color: var(--primary-color);
  text-align: left;
}

.cta-subtext {
  font-family: var(--font-secondary);
  font-size: var(--font-size-lg);
  line-height: 1.2;
  color: var(--text-color);
  max-width: 558px;
  text-align: left;
}

.cta-button {
  background: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-sm);
  padding: 12px 32px;
  font-size: var(--font-size-md);
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  min-width: 158px;
}

.illustration-container {
  position: absolute;
  top: 311px;
  left: 910px;
}

.illustration {
  max-width: 100%;
  width: 30vw;
  height: auto;
}

/* =========================  MOBILE STYLES (MAX 768px)  ========================= */
@media (max-width: 768px) {

  .navbar {
    height: 118px;
  }

  .logo-container {
    left: 14px;
    top: 77px;
  }

  .logo {
    width: 202px;
  }

  .hamburger {
    display: none;
    position: absolute;
    width: 24px;
    height: 18px;
    top: 76px;
    right: 20px;
    cursor: pointer;
  }

  .bar {
    width: 100%;
    height: 4px;
    background: var(--text-color);
    margin: 3px 0;
  }

  .mobile-menu {
    position: absolute;
    top: 118px;
    left: 0;
    width: 100%;
    background: var(--white);
    text-align: center;
    padding: 10px 0;
  }

  .mobile-menu a {
    display: block;
    padding: 10px;
    font-size: var(--font-size-md);
    text-decoration: none;
    color: var(--border-color);
  }

  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 130px;
  }

  .cta-section {
    width: 358px;
    height: 241px;
    position: relative;
    top: 173px;
    left: 16px;
    text-align: center;
  }

  .cta-title {
    font-size: var(--font-size-xl);
    font-weight: 700;
    line-height: 40px;
    color: var(--primary-color);
    text-align: center;
  }

  .cta-subtext {
    font-family: var(--font-secondary);
    font-size: var(--font-size-base);
    line-height: 19.36px;
    text-align: center;
    color: var(--text-color);
    max-width: 358px;
    margin: 10px auto;
  }

  .cta-button {
    width: 158px;
    height: 48px;
    font-size: var(--font-size-base);
    font-weight: bold;
    border-radius: var(--border-radius-sm);
    padding: 12px 32px;
    margin-top: 20px;
  }

  .illustration-container {
    position: relative;
    top: 470px;
    left: 49px;
  }

  .illustration {
    width: 304px;
    height: 262px;
  }
}

/* =========================  SMALL MOBILE STYLES (MAX 480px)  ========================= */
@media (max-width: 480px) {
  .cta-title {
    font-size: 28px;
  }

  .cta-subtext {
    font-size: var(--font-size-sm);
  }

  .cta-button {
    font-size: var(--font-size-sm);
  }

  .illustration {
    width: 90%;
  }
}